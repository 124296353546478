<template>
  <div class="r2e-input-time" :class="[{'error' : error }]">
    <label v-if="label" :for="`input-${id}`">{{ label }}</label>
    <div class="input-field" :class="{'disabled' : disabled}">
      <input
        :id="`input-${id}`"
        :name="id"
        type="time"
        :value="value"
        :disabled="disabled"
        :min="min"
        :max="max"
        :placeholder="placeholder"
        @input="
          $emit('set', $event.target.value);
          $emit('removeError');
        "
        @focusout="$emit('focusOut', $event.target.value)"
      >
    </div>
    <span v-if="error" class="error">
      {{ error }}
    </span>
  </div>
</template>

<script>
  export default {
    name: "R2eTime",
    props: {
      id: {type: String, required: true},
      type: {type: String, required: false, default: 'text'},
      placeholder: {type: String, required: false, default: 'text'},
      label: {type: String, required: false, default: null},
      value: {type: undefined, required: false, default: null},
      error: {type: String, required: false, default: null},
      disabled: {type: Boolean, required: false, default: false},
      min: {type: Number, required: false, default: null},
      max: {type: Number, required: false, default: null},
    },
    emits: ['removeError', 'set', 'focusOut'],

  }
</script>

<style lang="scss">
.r2e-input-time {
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 2.6;
    color: var(--r2e-secondary);
    padding-bottom: 5px;
  }

  div.input-field.disabled {
    opacity: 0.5;
  }

  div.input-field {
    width: 100%;
    display: flex;
    background-color: var(--r2e-secondary-100);
  }

  input::-webkit-date-and-time-value {
    text-align: left !important;
    margin-left: var(--margin-m);
  }

  div.input-field > input {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 4px;
    height: 6.1rem;
    font-size: 1.6rem;
    background-color: transparent;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
    padding-left: var(--padding-s);
    //padding: 1.9rem 1.6rem;

    font-weight: 500;
    color: var(--r2e-secondary);

    &::placeholder {
      color: var(--r2e-secondary-200);
      opacity: 1; /* Firefox */
      font-weight: 400;
    }

    &::-ms-input-placeholder { /* Edge 12 -18 */
      color: var(--r2e-secondary-200);
    }

    svg {
      color: var(--r2e-secondary)
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  span.error {
    color: #D50043;
    font-size: 1.2rem;
  }

  &.error {
    div.input-field input {
      background-color: #FBE8EE;
      color: #D50043;
    }
  }
}

[data-theme='dark'] {
  .r2e-input-time {
    label {
      color: var(--r2e-secondary-200);
    }

    div.input-field {
      background-color: var(--r2e-secondary-500);
    }

    div.input-field input {
      background-color: var(--r2e-secondary-500);
      color: var(--r2e-white);

      &::placeholder {
        color: var(--r2e-secondary-200);
      }

      &::-ms-input-placeholder {
        color: var(--r2e-secondary-200);
      }
    }

    span.error {
      color: var(--r2e-error);
    }

    &.error {
      div.input-field input {
        background-color: var(--r2e-primary-700);
        color: var(--r2e-error);
      }
    }
  }
}

</style>

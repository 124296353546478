

export async function themeMiddleware(to) {
  const hideTabs = to.meta.hideTabs;
  const bgColor = to.meta.bgColor;

  const root = document.documentElement;
  const isDarkMode = root.getAttribute('data-theme') === 'dark';

  if (bgColor) {
    root.style.setProperty('--app-background', isDarkMode ? 'var(--r2e-secondary-600)' : 'var(--r2e-primary)');
  } else {
    root.style.setProperty('--app-background', isDarkMode ? 'var(--r2e-secondary-600)' : 'var(--r2e-background)');
  }

  if(hideTabs) {
    document.body.classList.add('hide-tabs');
  }else {
    document.body.classList.remove('hide-tabs');
  }
}


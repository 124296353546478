import apiClient from '@/core/apiClient';

const APP_CODE = 'R2E-CUSTOMER';

export class AppConfigurationRepository {

  async fetchConfiguration() {
    try {
      const {data} = await apiClient.get(`/app/${APP_CODE}/configuration`);

      return data.data;
    } catch (error) {
      console.error('Failed to fetch app configuration:', error);
      throw error;
    }
  }
}

import { iconStorage } from "@/services/storage/iconStorage.js";
import icons from "@/svg/index.js";

class IconService {
  constructor() {
    this.iconStorage = iconStorage;
  }

  async initIcons() {
    try {
      const initialized = await this.iconStorage.getInitialized();

      if (initialized) {
        return;
      }

      const allIcons = Object.keys(icons);

      for (const iconName of allIcons) {
        const iconPromise = icons[iconName];
        const iconHtml = await iconPromise;
        await this.iconStorage.set(iconName, iconHtml.default);
        await this.iconStorage.setInitialized(true);
      }
    } catch (error) {
      console.error('Failed to initialize icons:', error);
    }
  }

  async fetchIcon(name) {
    try {
      const icon = await this.iconStorage.get(name);

      if (icon) {
        return icon;
      } else {
        const iconHtml = icons[name];
        if (!iconHtml) {
          throw new Error(`Icon not found for name: ${name}`);
        }
        await this.iconStorage.set(name, iconHtml);
        return iconHtml;
      }
    } catch (error) {
      console.error(`Failed to fetch icon "${name}":`, error);
      return null;
    }
  }
}

export const iconService = new IconService();

<template>
  <template v-if="onlyTakeout">
    <span class="toggle-delivery only-takeaway">
      <Icon name="info" />
      <span>{{ __t('app.no-delivery') }}</span>
    </span>
  </template>
  <template v-else>
    <span class="toggle-delivery">
      <b :class="{ active: deliveryMode === 'delivery' }">
        {{ deliveryMode === 'delivery' ? __t('app.delivery') : __t('app.takeaway') }}
      </b>
      <Toggle
        class="delivery-toggle"
        :active="deliveryMode === 'delivery'"
        @toggle="toggleDelivery"
      />
      <Tooltip position="left">{{ __t('app.no-delivery') }}</Tooltip>
    </span>
  </template>
</template>

<script setup>
  import {onMounted, ref} from 'vue';
  import { Toggle } from "@/ui";
  import { __t } from "@/globals";
  import Tooltip from "@/modules/global/parts/Tooltip.vue";
  import Icon from "@/ui/icon/Icon.vue";
  import {cartService} from "@/services/app/cartService.js";

  const deliveryMode = ref('takeout');
  const establishment = ref(null);
  const onlyTakeout = ref(false);

  onMounted(async () => {
    deliveryMode.value = await cartService.getDeliveryMode();
    establishment.value = await cartService.getEstablishment();
    onlyTakeout.value = establishment.value?.delivery === 'takeout'
  });

  const toggleDelivery = async () => {
    deliveryMode.value = deliveryMode.value === 'delivery' ? 'takeaway' : 'delivery';
    await cartService.setDeliveryMode(deliveryMode.value);
  };


</script>

<style lang="scss">
.toggle-delivery {
  display: flex;
  font-size: 1.2rem;
  align-items: center;

  &.only-takeaway {
    svg {
      width: 1.8rem;
      height: 1.8rem;
      color: var(--r2e-primary);
      margin-right: var(--margin-xs);
    }

    span {
      font-size: 1rem;
      max-width: 20rem;
      font-weight: 600;
      color: var(--r2e-primary);
    }
  }

  &.disabled {
    position: relative;

    .toggle, b {
      opacity: 0.3;
      pointer-events: none;
    }

    &:hover {
      .tooltip {
        display: flex;
        right: 20%;
      }
    }
  }

  b {
    margin-right: var(--margin-xs);
    font-weight: 600;

    &.active {
      color: var(--r2e-primary);
    }
  }
}
</style>

<template>
  <ul class="nav-links" :class="{ 'mt-m': !currentOrder }">
    <li>
      <a :href="__t('route.catalogue')" @click.prevent="navigateTo('catalogue')">
        <div class="icon-wrap">
          <Icon name="order" />
        </div>
        <span>{{ __t('nav.catalogue') }}</span>
      </a>
    </li>
    <li>
      <a class="mobile-only" :href="__t('route.cart')" @click.prevent="navigateTo('cart')">
        <div class="icon-wrap">
          <Icon name="bag" />
        </div>
        <span>{{ __t('nav.bag') }}</span>
      </a>
      <a class="desktop-only" :href="__t('route.checkout')" @click.prevent="navigateTo('checkout')">
        <div class="icon-wrap">
          <Icon name="bag" />
        </div>
        <span>{{ __t('nav.checkout') }}</span>
      </a>
    </li>
    <li>
      <a :href="__t('route.order-history')" @click.prevent="navigateTo('order-history')">
        <div class="icon-wrap">
          <Icon name="history" />
        </div>
        <span>{{ __t('nav.history') }}</span>
      </a>
    </li>
    <li>
      <a :href="__t('route.contact')" @click.prevent="navigateTo('contact')">
        <div class="icon-wrap">
          <Icon name="info" />
        </div>
        <span>{{ __t('nav.info') }}</span>
      </a>
    </li>
    <li v-if="PlatformService.isNative()">
      <a :href="__t('route.settings')" @click.prevent="navigateTo('settings')">
        <div class="icon-wrap">
          <Icon name="settings" />
        </div>
        <span>{{ __t('nav.settings') }}</span>
      </a>
    </li>
  </ul>
</template>

<script setup>
  import {defineProps, defineEmits} from 'vue';
  import Icon from "@/ui/icon/Icon.vue";
  import {__t} from "@/globals";
  import PlatformService from "@/services/PlatformService.js";

  defineProps({
    currentOrder: {
      type: Object,
      default: null,
    },
  });

  const emit = defineEmits(['navigate']);

  function navigateTo(route) {
    emit('navigate', route);
  }
</script>

<style lang="scss">
ul.nav-links {
  li {
    margin-bottom: var(--margin-s);
    padding-bottom: var(--padding-s);
    transition: var(--effect);
    border-bottom: 1px solid var(--color-grey);


    &:hover, :active {
      span {
        color: var(--r2e-primary);
      }
    }

    a {
      display: flex;
      align-items: center;

      &.mobile-only {
        display: flex;

        @media(min-width: 720px) {
          display: none;
        }
      }

      &.desktop-only {
        display: none;

        @media(min-width: 720px) {
          display: flex;
        }
      }
    }

    div.icon-wrap {
      width: 3rem;
      height: 3rem;
      border-radius: 8px;
      background-color: var(--r2e-secondary-100);
      display: flex;
      justify-content: center;

      i {
        svg {
          color: var(--r2e-secondary);
          width: 1.2rem;
          height: 1.2rem;
        }
      }
    }


    span {
      color: var(--r2e-secondary);
      font-weight: 500;
      margin-left: var(--margin-m);
      font-size: 1.2rem;
      text-transform: capitalize;
      transition: var(--effect);
    }

    @media(min-height: 700px) {
      margin-bottom: var(--margin-m);
      padding-bottom: var(--padding-m);

      div.icon-wrap {
        i {
          svg {
            color: var(--r2e-secondary);
            width: 1.4rem;
            height: 1.4rem;
          }
        }
      }

      span {
        color: var(--r2e-secondary);
        font-weight: 500;
        margin-left: var(--margin-m);
        font-size: 1.2rem;
        text-transform: capitalize;
      }
    }
  }
}

[data-theme='dark'] {
  ul.nav-links {
    li {
      border-bottom: 1px solid var(--r2e-secondary);

      span {
        color: var(--r2e-secondary-200);
      }

      &:hover {
        span {
          color: var(--r2e-primary);
        }
      }

      div.icon-wrap {
        background-color: var(--r2e-secondary-400);

        i svg {
          color: var(--r2e-white);
        }
      }
    }
  }
}
</style>



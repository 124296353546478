export default class AuthenticationToken {
  constructor(authenticationToken) {
    this.access_token = null;
    this.expires_in = null;
    this.refresh_token = null;
    this.token_type = null;

    if(authenticationToken){
      this.init(authenticationToken);
    }
  }

  init(authenticationToken){
    this.access_token = authenticationToken.access_token;
    this.expires_in = authenticationToken.expires_in;
    this.refresh_token = authenticationToken.refresh_token;
    this.token_type = authenticationToken.token_type;
  }
}

import { Drivers, Storage } from '@ionic/storage';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

// Storage keys as constants
const STORAGE_KEYS = {
  ICONS: 'icons_db',
};

class IconStorage {
  constructor() {
    this.storage = new Storage([Drivers.IndexedDB, CordovaSQLiteDriver._driver]);
    this.storage.create();
  }

  async setIcons(icons) {
    try {
      await this.storage.set(STORAGE_KEYS.ICONS, icons);
    } catch (error) {
      console.error('Failed to set icons:', error);
    }
  }

  async getIcons() {
    try {
      return await this.storage.get(STORAGE_KEYS.ICONS) || {};
    } catch (error) {
      console.error('Failed to get icons:', error);
      return {};
    }
  }

  async clearIcons() {
    try {
      await this.storage.remove(STORAGE_KEYS.ICONS);
    } catch (error) {
      console.error('Failed to clear icons:', error);
    }
  }

  // New method to set an individual icon
  async set(key, value) {
    try {
      const icons = await this.getIcons();
      icons[key] = value; // Update the specific icon
      await this.setIcons(icons); // Save updated icons
    } catch (error) {
      console.error(`Failed to set icon "${key}":`, error);
    }
  }

  async get(key) {
    try {
      const icons = await this.getIcons();
      return icons[key];
    } catch (error) {
      console.error(`Failed to get icon "${key}":`, error);
    }
  }

  async setInitialized(value) {
    try {
      await this.storage.set('initialized', value);
    } catch (error) {
      console.error('Failed to set initialized:', error);
    }
  }

  async getInitialized() {
    try {
      return await this.storage.get('initialized') || false;
    } catch (error) {
      console.error('Failed to get initialized:', error);
      return false;
    }
  }
}

// Export a single instance (Singleton pattern)
export const iconStorage = new IconStorage();

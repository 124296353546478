<template>
  <div class="lang-switcher" :class="{'is_open': open}" @click="toggleLangSwitcher">
    <span v-if="currentLocale" class="lang">{{ currentLocale }}</span>
    <Icon class="chevron" name="chevron" />

    <div
      ref="lang-options"
      class="lang-options"
      :class="{'active': open}"
    >
      <ul>
        <li v-for="(lang, i) in options" :key="`lang_${i}`" @click="setLocale(lang.key)">
          {{ lang.text }}
          <Icon v-if="lang.key === currentLocale" name="check" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, onMounted } from 'vue';
  import Icon from "@/ui/icon/Icon.vue";
  import { __t } from "@/globals";
  import { onClickOutside } from "@vueuse/core";
  import { LocaleService } from "@/configuration/localeService.js";
  import router from "@/router/index.js";
  import PlatformService from "@/services/PlatformService.js";
  import {preferenceService} from "@/services/app/preferenceService.js";

  // Local state
  const open = ref(false);
  const native = PlatformService.isNative();
  const currentLocale = ref(null);

  const options = ref([
    { key: 'nl', text: __t('app.lang.dutch') },
    { key: 'en', text: __t('app.lang.english') },
    { key: 'fr', text: __t('app.lang.french') },
    { key: 'tr', text: __t('app.lang.turkish') },
  ]);

  // References
  const langOptionsRef = ref(null);

  // Methods
  const toggleLangSwitcher = () => {
    open.value = !open.value;
  };

  const setLocale = async (key) => {
    if(native){
      await preferenceService.setLanguage(key);

      preferenceService.setLocale(key);

      window.location.reload();
    } else {
      await LocaleService.changeLocale(key, router.currentRoute.value);

      setTimeout(() => {
        window.location.reload();
      }, 500);
    }


  };

  onMounted(async () => {
    currentLocale.value = await preferenceService.getLanguage();

    onClickOutside(langOptionsRef, () => {
      if (open.value) {
        toggleLangSwitcher();
      }
    });
  });
</script>

<style lang="scss">
.lang-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  border-radius: 99rem;
  height: 2.8rem;
  background-color: var(--r2e-secondary-100);
  transition: var(--effect);
  cursor: pointer;
  position: relative;

  .lang {
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 600;
  }

  .chevron {
    margin-left: var(--margin-xs);

    svg {
      color: var(--r2e-secondary);
      width: 0.8rem;
      height: 0.8rem;
      transform: rotate(-90deg);
    }
  }

  &:hover {
    transform: scale(1.05);
  }

  &.is_open {
    transition: 0s all;
    transform: scale(1);
  }

  .lang-options {
    position: absolute;
    display: none;
    z-index: 9999;
    background-color: #fff;
    top: calc(100% + var(--margin-s));
    right: 0;
    box-shadow: var(--shadow);
    padding: var(--padding-s);
    border-radius: 0.5rem;

    &.active {
      display: block;
    }

    ul {
      li {
        padding: 3px 10px;
        border-radius: 9rem;
        font-size: 1.1rem;
        font-weight: 600;
        margin-bottom: var(--margin-s);
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        svg {
          margin-left: var(--margin-s);
          color: var(--r2e-secondary);
          width: 0.8rem;
          height: 0.8rem;
        }

        &:hover {
          background-color: var(--r2e-secondary-100);
        }
      }

      li:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

[data-theme='dark'] {
  .lang-switcher {
    .chevron {
      svg {
        color: var(--r2e-white);
      }
    }

    .lang {
      color: var(--r2e-white);
    }

    .lang-options {
      background-color: var(--r2e-secondary);

      ul {
        li {
          color: var(--r2e-white);

          svg {
            color: var(--r2e-white);
          }

          &:hover {
            background-color: var(--r2e-secondary-600);
          }
        }
      }
    }
  }
}
</style>

<template>
  <ion-header />
  <ion-content class="ion-padding content-radius" overflow-scroll="false">
    <div class="inner-content">
      <div class="modal-header" />
      <div class="modal-main mb-l">
        <div class="mb-m">
          <p>{{ __t('auth.registering-code.title') }}</p>
        </div>

        <r2e-reset-code
          :loading="loading"
          @code-entered="submitCode"
        />

        <p class="link mt-m">
          {{ __t('auth.register-code.no-code') }}
          <span
            :class="{'disabled': resendLinkDisabled}"
            class="resend-code-button"
            @click="resendCode"
          >
            {{ __t('auth.register-code.resend') }}
          </span>
        </p>
      </div>
    </div>
  </ion-content>
</template>

<script setup>
  import {onMounted, ref} from 'vue';
  import {$showToast, __t} from '@/globals';
  import {modalController, IonHeader, IonContent} from "@ionic/vue";
  import axios from "axios";
  import {apiUrl} from "@/configuration/api.js";
  import R2eResetCode from "@/ui/resetcode/R2eResetCode.vue";
  import {userService} from "@/services/app/userService.js";

  const loading = ref(false);
  const user = ref(null);
  const resendLinkDisabled = ref(false);

  const submitCode = async (code) => {
    loading.value = true;

    await axios.post(apiUrl('verify-order-register-code'), {
      register_code: code,
      email: user.value.email
    }).then(async () => {
      $showToast(__t('success.verify-mail'), 'success', 3000);

      await userService.setRegisterData(null);
      await userService.setUserEmailVerificationStatus(true);

      await modalController.dismiss();

      setTimeout(async () => {
        loading.value = false;
      }, 1000);

    }).catch(() => {
      $showToast(__t('error.verify-mail'), 'error', 5000);
      loading.value = false;
    });
  };

  const resendCode = async () => {
    resendLinkDisabled.value = true;
    await axios.post(apiUrl('resend-register-code'), {
      email: user.value.email
    }).then(() => {
      $showToast(__t('success.resend-register-code'), 'success', 3000);
    }).catch(() => {
      $showToast(__t('error.resend-register-code'), 'error', 5000);
    }).finally(() => {
      setTimeout(() => {
        resendLinkDisabled.value = false;
      }, 5000);
    });
  };

  onMounted(async  () => {
    user.value = await userService.getUser();
  });
</script>


<style lang="scss">
.ion-modal-verify-email {
  .modal-main {
    margin: auto auto var(--margin-l);
    max-width: 40rem;

    p {
      font-size: 1.2rem;
      font-weight: 500;
      color: var(--r2e-secondary-200);
      line-height: 1.7;
    }
  }

  .modal-footer {
    max-width: 36rem;
    margin: auto auto var(--margin-l);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .error-message {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 var(--margin-s);
    height: 3rem;
    background-color: var(--r2e-primary-100);
    margin: var(--margin-m) auto;
    font-size: 1rem;
    font-weight: 500;
    color: var(--r2e-error);

    max-width: 36rem;

    @media(min-width: 1080px) {
      max-width: 46rem;
    }
  }

  .link {
    font-size: 1.2rem;
    text-align: center;

    span {
      color: var(--r2e-primary);
      font-weight: 500;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &.disabled {
        color: var(--r2e-secondary-100);
        pointer-events: none;
      }
    }
  }
}

[data-theme='dark'] {
  .ion-modal-verify-email {
    h3 {
      color: var(--r2e-white);

      svg {
        fill: var(--r2e-white);
      }
    }

    .modal-footer {
      button {
        background-color: var(--r2e-primary);
        color: var(--r2e-white);
      }
    }
  }
}

</style>

// services/preferencesStorage.js
import {Drivers, Storage} from '@ionic/storage';
import CordovaSQLiteDriver from "localforage-cordovasqlitedriver";

// Storage keys as constants
const STORAGE_KEYS = {
  PREFERENCES: 'cart_db',
};

class CartStorage {

  constructor() {
    this.storage = new Storage([Drivers.IndexedDB, CordovaSQLiteDriver._driver]);
    this.storage.create();
  }

  async setCart(cart) {
    try {
      await this.storage.set(STORAGE_KEYS.PREFERENCES, cart);
    } catch (error) {
      console.error('Failed to set settings:', error);
    }
  }

  async getCart() {
    try {
      return await this.storage.get(STORAGE_KEYS.PREFERENCES) || {};
    } catch (error) {
      console.error('Failed to get settings:', error);
      return {};
    }
  }

  async clearCart() {
    try {
      await this.storage.remove(STORAGE_KEYS.PREFERENCES);
    } catch (error) {
      console.error('Failed to clear settings:', error);
    }
  }

  async set(key, value) {
    try {
      const cart = await this.getCart();
      cart[key] = value;
      await this.setCart(cart);
    } catch (error) {
      console.error(`Failed to set settings property "${key}":`, error);
    }
  }

  async get(key) {
    try {
      const preferences = await this.getCart();
      return preferences[key];
    } catch (error) {
      console.error(`Failed to get cart property "${key}":`, error);
    }
  }

  async getCartLines() {
    try {
      const preferences = await this.getCart();
      return preferences['lines'] ??  [];
    } catch (error) {
      console.error(`Failed to get cart lines":`, error);
    }
  }

  async setCartLines(lines) {
    try {
      const cart = await this.getCart();
      cart['lines'] = lines;
      await this.setCart(cart);
    } catch (error) {
      console.error(`Failed to set cart lines":`, error);
    }
  }

  async getCartSummary() {
    try {
      const cart = await this.getCart();
      return cart['summary'] ??  {};
    } catch (error) {
      console.error(`Failed to get cart summary":`, error);
    }
  }

  async setCartSummary(summary) {
    try {
      const cart = await this.getCart();
      cart['summary'] = summary;
      await this.setCart(cart);
    } catch (error) {
      console.error(`Failed to set cart summary":`, error);
    }
  }
}

// Export a single instance (Singleton pattern)
export const cartStorage = new CartStorage();

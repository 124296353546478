import session from "@/modules/session";
import User from "@/resources/User";
import {checkEmail} from "@/modules/global/helpers/domHelper.js";

function getFullName(user){
  if(user.first_name && user.last_name){
    return user.first_name + ' ' + user.last_name
  }

  return null;

}

function toggleRegister(){
  session.commit('user/toggleRegister');
}

function setUser(data) {
  let user = new User();

  user.id = data.id;
  user.first_name = data.first_name;
  user.last_name = data.last_name;
  user.email = data.email;
  user.phone = data.phone;
  user.password = data.password;
  user.register = data.register;

  session.commit('user/setUser', user);
}

function logout(){
  session.commit('user/reset');
  session.commit('cart/reset');
  session.commit('order/reset');
  session.commit('order/setCurrentOrder', null);
  session.commit('user/setRegisteringEmail', null);
  session.commit('user/setRegisteringToken', null);
  session.commit('user/setRegistering', false);
}

export {
  getFullName,
  toggleRegister,
  setUser,
  logout
}

<template>
  <div class="modal-main">
    <div class="article-supplements">
      <template v-for="(group, i) in visibleSupplementGroups" :key="`supp_${i}`">
        <SupplementGroup
          :delivery="delivery"
          :group="group"
          @update-supplement="val => emit('update-supplement' , group, val.item)"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
  import {defineProps, computed, defineEmits} from 'vue';
  import SupplementGroup from "@/modals/parts/SupplementGroup.vue";

  const props = defineProps({
    article: {type: Object, required: true},
    delivery: {type: Boolean, required: true},
    cartItem: {type: Object, required: true},
  });

  const emit = defineEmits(['updateSupplement']);

  const visibleSupplementGroups = computed(() =>
    props.article.supplement_groups.filter(grp => grp.visible)
  );
</script>

<style lang="scss">
.modal-article {
  .modal-main {
    margin-top: 0;

    padding: 0 var(--padding-m);

    @media (min-width: 720px) {
      padding: var(--margin-m);
      padding-right: 0;
      max-height: 100%;
      padding-bottom: 3rem;
      overflow-y: auto;
    }

  }
}


[data-theme='dark'] {
  .modal-article {

  }
}
</style>

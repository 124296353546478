export default class User {
  constructor(user) {
    this.id = null;
    this.first_name = null;
    this.last_name = null;
    this.email = null;
    this.email_verified = false;
    this.phone = null;
    this.password = null;
    this.auth_provider = null;
    this.profile_image_path = null;

    if(user){
      this.init(user);
    }
  }

  init(user){
    this.id = user.id;
    this.first_name = user.first_name;
    this.last_name = user.last_name;
    this.email = user.email;
    this.email_verified = user.email_verified;
    this.phone = user.phone;
    this.password = user.password;
    this.auth_provider = user.auth_provider ?? user.provider;
    this.profile_image_path = user.profile_image_path;
  }
}

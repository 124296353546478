<template>
  <app-modal-mini
    :button-disabled="disabled"
    @submitted="submit"
  >
    <template #header>
      <h3>
        <Icon name="r2h" />
        {{ __t('checkout.ready2help.title') }}
      </h3>
    </template>

    <template #main>
      <p class="mb-m">
        {{ __t(('app.ready2help.body')) }}
      </p>

      <R2eSelect
        v-if="!loading"
        id="select-r2h"
        :placeholder="__t('app.select-organisation')"
        :selected="currentOrganisation"
        :options="options"
        :label="__t('app.organisation')"
        @setter="val => setOrganisation(val)"
      />
    </template>
  </app-modal-mini>
</template>
<script setup>
  import {ref, onMounted} from 'vue';
  import { $showToast, __t } from "@/globals";
  import { modalController } from "@ionic/vue";
  import axios from "axios";
  import { apiUrl } from "@/configuration/api";
  import {preferenceService} from "@/services/app/preferenceService.js";
  import AppModalMini from "@/modals/AppModalMini.vue";
  import Icon from "@/ui/icon/Icon.vue";
  import R2eSelect from "@/ui/select/R2eSelect.vue";

  const loading = ref(true);
  const disabled = ref(true);
  const currentOrganisation = ref(null);
  const options = ref([]);

  const setOrganisation = (id) => {
    currentOrganisation.value = options.value.find(option => option.value === id);
    disabled.value = false;
  }

  const submit = async () => {

    const payload = {
      id: currentOrganisation.value.value,
      name: currentOrganisation.value.label,
    };

    await preferenceService.setReady2Help(payload);
    $showToast(__t('success.checkout.ready2help'), 'success');
    await modalController.dismiss();
  }

  const fetchSponsors = async () => {
    await axios.get(apiUrl('organizations')).then(async ({data}) => {
      data.data.forEach(organization => {
        options.value.push({
          value: organization.id,
          label: organization.name,
        })
      });

      currentOrganisation.value = await preferenceService.getReady2Help();

      loading.value = false;
    })
  }

  onMounted(async () => {
    await fetchSponsors();

  });
</script>

<style lang="scss">
.ion-modal-mini.ion-modal-ready2help {
  --height: 50rem;

  p {
    font-size: 1.4rem;
    color: var(--r2e-secondary-200);
    line-height: 1.7;
  }
}
</style>

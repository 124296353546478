export default class RegisterData {
  constructor(registerData) {
    this.registering = false;
    this.email = null;
    this.social_token = null;
    this.password = null;
    this.user_id = null;
    this.provider = null;

    if(registerData){
      this.init(registerData);
    }
  }

  init(registerData){
    this.registering = registerData.registering;
    this.email = registerData.email;
    this.social_token = registerData.social_token;
    this.password = registerData.password;
    this.user_id = registerData.user_id;
    this.provider = registerData.provider;
  }
}

import { createStore } from 'vuex';

const store = createStore({
  state: {
    cartUpdated: false,
    cartSummaryUpdated: false,
    addressUpdated: false,
    appPreferencesUpdated: false,
    orderPreferencesUpdated: false,
    registerDataUpdated: false,
    userUpdated: false,
    cartLineCommentUpdated: {}
  },
  mutations: {
    SET_CART_UPDATED(state) {
      state.cartUpdated = !state.cartUpdated;
    },
    SET_CART_SUMMARY_UPDATED(state) {
      state.cartSummaryUpdated = !state.cartSummaryUpdated;
    },
    SET_ADDRESS_UPDATED(state) {
      state.addressUpdated = !state.addressUpdated;
    },
    SET_CART_LINE_COMMENT_UPDATED(state, uuid) {
      state.cartLineCommentUpdated[uuid] = !state.cartLineCommentUpdated[uuid]
    },
    SET_ORDER_PREFERENCES_UPDATED(state) {
      state.orderPreferencesUpdated = !state.orderPreferencesUpdated;
    },
    SET_APP_PREFERENCES_UPDATED(state) {
      state.appPreferencesUpdated = !state.appPreferencesUpdated;
    },
    SET_USER_UPDATED(state) {
      state.userUpdated = !state.userUpdated;
    },
    SET_REGISTER_DATA_UPDATED(state) {
      state.registerDataUpdated = !state.registerDataUpdated
    }
  },
  actions: {
    updateCartStatus({ commit }) {
      commit('SET_CART_UPDATED');
    },
    updateCartSummaryStatus({ commit }) {
      commit('SET_CART_SUMMARY_UPDATED');
    },
    updateAddressStatus({ commit }) {
      commit('SET_ADDRESS_UPDATED');
    },
    updateCartLineCommentStatus({ commit }, uuid) {
      commit('SET_CART_LINE_COMMENT_UPDATED', uuid);
    },
    updateOrderPreferences({ commit }) {
      commit('SET_ORDER_PREFERENCES_UPDATED');
    },
    updateAppPreferences({ commit }) {
      commit('SET_APP_PREFERENCES_UPDATED');
    },
    updatedUser({ commit }) {
      commit('SET_USER_UPDATED');
    },
    updatedRegisterData({ commit }) {
      commit('SET_REGISTER_DATA_UPDATED');
    }
  },
  getters: {
    isCartUpdated: (state) => state.cartUpdated,
    isCartSummaryUpdated: (state) => state.cartSummaryUpdated,
    isAddressUpdated: (state) => state.addressUpdated,
    isCartLineCommentUpdated: (state) => state.cartLineCommentUpdated,
    isOrderPreferencesUpdated: (state) => state.orderPreferencesUpdated,
    isAppPreferencesUpdated: (state) => state.appPreferencesUpdated,
    isUserUpdated: (state) => state.userUpdated,
    isRegisterDataUpdated: (state) => state.registerDataUpdated
  },
  namespaced: true,
});

export default store;

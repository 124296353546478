export default class CartLineSupplement {
  constructor(supplement) {
    this.id = null;
    this.value = null;
    this.description = null;
    this.free = false;
    this.price_delivery = 0.00;
    this.price_takeout = 0.00;
    this.sorting = null;
    this.selected = false;
    this.visible = false;
    this.alcohol = false;
    this.alcohol_percentage = null;


    if(supplement){
      this.init(supplement);
    }
  }

  init(data){
    this.id = data.id;
    this.value = data.value;
    this.description = data.description;
    this.free = data.free;
    this.price_delivery = data.price_delivery;
    this.price_takeout = data.price_takeout;
    this.sorting = data.sorting;
    this.selected = data.selected;
    this.visible = data.visible;
    this.alcohol = data.alcohol;
    this.alcohol_percentage = data.alcohol_percentage;
  }
}

import { preferenceService } from "@/services/app/preferenceService.js";
import { LocaleService } from "@/configuration/localeService.js";

const supportedLocales = ['nl', 'en', 'fr', 'tr'];

export async function localeMiddleware(to, from, next) {
  const newLocale = to.query.locale;
  const currentLocale = await preferenceService.getLocale();

  if (newLocale && supportedLocales.includes(newLocale) && newLocale !== currentLocale) {
    await LocaleService.changeLocale(newLocale, to);
  }
  return true;
}

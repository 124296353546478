<template>
  <span class="toggle-register">
    <template v-if="register">
      <b class="active">{{ __t('checkout.create-account') }}</b>
    </template>
    <template v-else>
      <b>{{ __t('checkout.create-account') }}</b>
    </template>

    <Toggle :active="registerData?.registering" @toggle="toggled" />
  </span>
</template>

<script setup>
  import {computed, defineEmits, onBeforeMount, ref, watch} from 'vue';
  import {Toggle} from "@/ui";
  import {__t} from "@/globals";
  import {userService} from "@/services/app/userService.js";
  import store from "@/store/store.js";

  const registerData = ref(null);
  const registerDataUpdated = computed(() => store.getters['isRegisterDataUpdated']);

  const emit = defineEmits(['toggled']);

  const toggled = async () => {
    const registerDataObj = registerData.value;
    registerDataObj.registering = !registerDataObj.registering;

    await userService.setRegisterData(registerDataObj);
    await emit('toggled');
  };

  onBeforeMount(async () => {
    await initData();
  });

  watch(registerDataUpdated, async (newValue, oldValue) => {
    if (newValue !== oldValue) {
      await initData();
    }
  });

  const initData = async () => {
    registerData.value = await userService.getRegisterData();
  }
</script>

<style lang="scss">
.toggle-register {
  display: flex;
  font-size: 1.2rem;
  align-items: center;

  b {
    margin-right: var(--margin-xs);
    font-weight: 600;

    &.active {
      color: var(--r2e-primary);
    }
  }
}
</style>

<template>
  <div class="r2e-input" :class="{ error: error }">
    <label v-if="label" :for="`input-${id}`">{{ label }}</label>
    <div class="input-field" :class="{ disabled: disabled }">
      <ion-input
        :id="`input-${id}`"
        :name="name ? name : id"
        :autocomplete="autocomplete ?? 'off'"
        :type="textType"
        :value="value"
        :disabled="disabled"
        :placeholder="placeholder"
        :pattern="pattern"
        @ion-input="handleInput"
        @ion-blur="handleFocusOut"
      />
      <span
        v-if="type === 'password'"
        tabindex="-1"
        class="password-show"
        @click.prevent="toggleShowPassword"
      >
        <Icon v-if="!showPassword" name="eye-closed" />
        <Icon v-else name="eye" />
      </span>
    </div>
    <span v-if="error" class="error">{{ error }}</span>
  </div>
</template>

<script setup>
  import {defineProps, defineEmits, ref} from 'vue';
  import Icon from "@/ui/icon/Icon.vue";
  import { IonInput } from '@ionic/vue';

  const props = defineProps({
    id: { type: String, required: true },

    type: { type: String, default: 'text' },
    placeholder: { type: String, default: '' },
    label: { type: String, default: null },
    value: { type: [String, Number], default: null },
    name: { type: String, required: false, default: null },
    error: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    pattern: { type: String, default: null },
    autocomplete: { type: String, required: false, default: null }
  });

  const showPassword = ref(false);

  const textType = ref(props.type);

  function toggleShowPassword() {
    showPassword.value = !showPassword.value;

    if(props.type === 'password'){
      textType.value = showPassword.value ? 'text' : 'password';
    }
  }

  function handleInput(event) {
    emit('set', event.target.value);
    emit('removeError');
  }

  function handleFocusOut(event) {
    emit('focusOut', event.target.value);
  }

  const emit = defineEmits(['removeError', 'set', 'focusOut']);
</script>

<style lang="scss">
.r2e-input {
  label {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 2.6;
    color: var(--r2e-secondary);
    padding-bottom: 5px;
  }

  .input-field.disabled {
    opacity: 0.8;

    ion-input {
      --color: var(--r2e-secondary-200);
      --placeholder-color: var(--r2e-secondary-200);
    }
  }

  .input-field ion-input {
    // Reset Ionic default styles
    --background: transparent;
    --background-focused: transparent;
    --background-hover: transparent;

    --border-color: transparent;
    --border-radius: 0;
    --border-style: none;
    --border-width: 0;

    --highlight-color-focused: var(--r2e-secondary-200);
    --highlight-color-invalid: transparent;
    --highlight-color-valid: transparent;

    // Apply our custom styles
    --padding-start: 1.6rem;
    --padding-end: 1.6rem;
    --padding-top: 1.9rem;
    --padding-bottom: 1.9rem;

    --font-size: 1.6rem !important;
    --color: var(--r2e-secondary);
    --placeholder-color: var(--r2e-secondary-200);
    --placeholder-opacity: 1;
    --placeholder-font-weight: 500;

    background: var(--r2e-secondary-100);
    font-size: 1.4rem !important;
    font-weight: 500;
    height: 6.1rem;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: var(--effect);

    &:hover {
      border: 1px solid var(--r2e-secondary-200);
      --placeholder-opacity: 0.8;
    }

    .native-wrapper {
      font-size: 1.4rem !important;
    }

    .input-highlight {
      display: none !important;
    }
  }

  .input-field > input {
    background-color: var(--r2e-secondary-100);
    color: var(--r2e-secondary);
    font-size: 1.4rem;
    width: 100%;
    font-weight: 500;
    height: 6.1rem;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 1.9rem 1.6rem;
    transition: var(--effect);

    ::placeholder {
      color: var(--r2e-secondary-200);
      font-weight: 500;
    }

    &:hover {
      border: 1px solid var(--r2e-secondary-200);
    }

    &:focus {
      //border: 1px solid var(--r2e-secondary);
      box-shadow: 0 0 0 1px var(--r2e-secondary);
    }
  }

  .input-field {
    position: relative;

    span.password-show {
      position: absolute;
      z-index: 9;
      right: var(--margin-m);
      top: calc(50% - 1.4rem);
      width: 2.8rem;
      height: 2.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;

      svg {
        color: var(--r2e-secondary-200);
        width: 2rem;
        height: 2rem;
      }
    }
  }

  span.error {
    color: var(--r2e-error);
    font-size: 1.2rem;
  }

  &.error .input-wrapper {
    border: 1px solid var(--r2e-error);
    background-color: var(--r2e-primary-100);
  }

  &.error .input-field input {
    background-color: var(--r2e-primary-100);
    color: var(--r2e-error);
  }

  &.error .input-field ion-input {
    background: var(--r2e-primary-100);
    --color: var(--r2e-error);
    --placeholder-color: var(--r2e-error);
  }
}

[data-theme='dark'] {
  .r2e-input {
    label {
      color: var(--r2e-secondary-200);
    }

    .input-field ion-input {
      //--color: var(--r2e-secondary-200);
      //--placeholder-color: var(--r2e-secondary-200);
      --highlight-color-focused: var(--r2e-secondary-200);
      border: none;

      --placeholder-opacity: 0.5;
      --placeholder-font-weight: 500;

      --background: var(--r2e-secondary-500); // Changed from 500 to 600
      color: var(--r2e-secondary-100); // Changed from white to secondary-200

      div {
        color: var(--r2e-secondary-100);
      }

      &.ion-focused {
        border: 1px solid var(--r2e-secondary-200);
        box-shadow: 0 0 0 1px var(--r2e-secondary-200);
        caret-color: var(--r2e-secondary-200); // Add this line
      }
    }

    span.error {
      color: var(--r2e-error);
    }

    &.error .input-wrapper {
      border: 1px solid var(--r2e-error);
      background-color: var(--r2e-primary-700);
    }

    &.error .input-field input {
      background-color: var(--r2e-primary-700);
      color: var(--r2e-error);
    }
  }
}

// Add this keyframe animation at the end of the style block
@keyframes blink-caret {
  from, to { caret-color: transparent; }
  50% { caret-color: var(--r2e-secondary); }
}


</style>

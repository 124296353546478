<template>
  <Teleport :to="teleportTarget">
    <div
      class="toast"
      :class="[
        `toast--${status}`,
        show ? 'fadeInTop' : 'fadeOutBottom'
      ]"
      :style="toastStyle"
    >
      <div class="wrap">
        <div class="icon">
          <Icon :name="status" />
        </div>
        <div class="content">
          <h2 v-html="title" />
          <div v-if="body" class="body" v-html="body" />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
  import {ref, onMounted, defineProps, defineEmits, onBeforeMount} from 'vue';
  import Icon from '@/ui/icon/Icon.vue';

  const props = defineProps({
    title: { type: String, default: null },
    body: { type: String, default: null },
    status: { type: String, default: 'alert' },
    position: { type: String, default: 'bottom' },
    timeout: { type: Number, default: 2000 },
  });

  const emit = defineEmits(['close']);

  const show = ref(true);
  const tabsHeight = ref(0);
  const toastStyle = ref({});
  const teleportTarget = ref('body');

  const setToastBottom = () => {
    if (tabsHeight.value > 0) {
      teleportTarget.value = '.tabs-inner';
      toastStyle.value = { bottom: `0`, height: `5rem`, minHeight: `none`, zIndex: 99 };
    }else {
      teleportTarget.value = 'body';
      toastStyle.value = { bottom: `0`, minHeight: `7rem` };
    }
  }

  onMounted(() => {

    setTimeout(() => {
      show.value = false;
    }, props.timeout);

    setTimeout(() => {
      emit('close');
    }, props.timeout + 1000);
  });

  onBeforeMount(() => {
    const bottomTabs = document.querySelector('.bottom-tabs');

    if (bottomTabs && !document.body.classList.contains('hide-tabs')) {

      tabsHeight.value = bottomTabs.getBoundingClientRect().height;
    }

    setToastBottom();
  });
</script>

<style lang="scss">
.toast {
  left: 0;
  position: fixed;
  background-color: var(--r2e-secondary-100);
  border-radius: 0;
  overflow: hidden;
  color: var(--r2e-white);
  padding: var(--margin-s);
  display: flex;
  width: 100%;
  margin: auto;
  min-height: 4rem;
  max-width: none;

  div.wrap {
    width: 100%;
    display: flex;
    max-width: 96rem;
    height: 100%;
    align-items: flex-start;
    justify-content: center;
    margin-top: var(--margin-s);
    margin-bottom: auto;
  }

  @media(min-width: 680px){
    min-height: 5rem;

    div.wrap {
      display: flex;
      align-items: center;;
      justify-content: center;
      margin: auto;
    }
  }

  div.icon {
    width: 1.2rem;
    height: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: auto;
    margin-right: var(--margin-s);

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  div.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: var(--ion-safe-area-bottom);

    h2 {
      font-size: 1.2rem;
      font-weight: 500;
      color: #fff;
      margin-bottom: 0;
      line-height: 1;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    div.body {
      margin-top: 1rem;
      color: var(--r2e-white);
    }
  }

  &--success {
    background-color: var(--r2e-success);
    div.icon svg {
      fill: var(--r2e-white);
    }
  }

  &--alert {
    background-color: var(--r2e-secondary-200);
  }

  &--error {
    background-color: var(--r2e-primary);
    div.icon svg {
      fill: var(--r2e-white);
    }

    div.content h2 {
      color: #fff;
    }
  }

  &--info {
    background-color: var(--r2e-secondary);
  }

  button.close-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 2rem;
    border: none;
    background-color: transparent;
    cursor: pointer;

    svg {
      width: 1.2rem;
      height: 1.2rem;
      fill: #fff;
      opacity: 0.4;
    }
  }
}

@keyframes fadeInTop {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.fadeInTop {
  animation-timing-function: ease-in-out;
  animation-duration: 400ms;
  animation-name: fadeInTop;
}

@keyframes fadeOutBottom {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

.fadeOutBottom {
  animation-timing-function: ease-in-out;
  animation-duration: 1000ms;
  animation-name: fadeOutBottom;
}

.toast {
  transition: transform 0.8s ease-in-out, opacity 0.4s ease-in-out;
}

</style>

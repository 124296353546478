<template>
  <app-modal-mini
    :submit-txt="__t('app.terms.submit')"
    @submitted="submitTerms"
  >
    <template #header>
      <h3>{{ __t('app.modal.terms.title') }}</h3>
    </template>

    <template #main>
      <ul>
        <li><a target="_blank" href="https://ready2eat.be/policy/terms">{{ __t('contact.terms') }}</a></li>
        <li><a target="_blank" href="https://ready2eat.be/policy/privacy">{{ __t('contact.privacy') }}</a></li>
        <li><a target="_blank" href="https://ready2eat.be/policy/gdpr">{{ __t('contact.gdpr') }}</a></li>
      </ul>
    </template>
  </app-modal-mini>
</template>

<script setup>
  import { defineProps } from 'vue';
  import {__t} from "@/globals";
  import { modalController } from "@ionic/vue";
  import AppModalMini from "@/modals/AppModalMini.vue";
  import {preferenceService} from "@/services/app/preferenceService.js";

  defineProps({
    item: { type: Object, required: false, default: () => null },
  });

  const submitTerms = async () => {
    await preferenceService.setPrivacyPermission(true);
    await modalController.dismiss();
  }
</script>

<style lang="scss">
.ion-modal-terms {
  --height: 44rem;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      padding-bottom: var(--margin-m);
      //border-bottom: 1px solid var(--r2e-secondary-100);
    }

    a {
      font-size: 1.2rem;
      text-decoration: underline;
      color: var(--r2e-secondary);
      font-weight: 500;
    }
  }
}

[data-theme="dark"] {
  .ion-modal-terms {
    a {
      color: var(--r2e-secondary-200);
    }
  }
}
</style>

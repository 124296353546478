<template>
  <div
    :style="containerStyles"
    class="app-button-container"
    :class="{ disabled: (props.loading || props.disabled), 'native-button': native }"
  >
    <SmoothCorners
      :style="`color: ${dark ? props.backgroundColorDark : props.backgroundColor}`"
      :width="props.width"
      :height="props.height"
      :corner-radius="props.cornerRadius"
    />
    <button :style="buttonStyles" class="app-button">
      <template v-if="props.loading">
        <Icon class="loader" name="loader" />
      </template>
      <template v-else>
        <Icon v-if="icon" class="button-icon" :name="icon" />
        <slot />
      </template>
    </button>
  </div>
</template>

<script setup>
  import { defineProps, computed } from 'vue';
  import SmoothCorners from "@/modules/global/SmoothCorners.vue";
  import Icon from "@/ui/icon/Icon.vue";
  import PlatformService from "@/services/PlatformService.js";

  const props = defineProps({
    backgroundColor: {
      type: String,
      default: 'var(--r2e-secondary)',
    },
    backgroundColorDark: {
      type: String,
      default: 'var(--r2e-primary)',
    },
    width: {
      type: [Number, String],
      default: 360,
    },
    height: {
      type: [Number, String],
      default: 60,
    },
    cornerRadius: {
      type: [Number, String],
      default: 10,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
  });

  const dark = computed(() => document.documentElement.dataset.theme === 'dark');

  const native = PlatformService.isNative();

  const containerStyles = computed(() => ({
    position: 'relative',
    display: 'inline-block',
    width: typeof props.width === 'number' ? `${props.width}px` : props.width,
    height: typeof props.height === 'number' ? `${props.height}px` : props.height,
  }));

  const buttonStyles = computed(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: dark.value ? 'var(--r2e-primary)' : props.backgroundColor,
    border: 'none',
    borderRadius: `${props.cornerRadius}px`,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: props.backgroundColor === 'var(--r2e-white)' && dark.value === false ? 'var(--r2e-secondary)' : 'var(--r2e-white)',
    fontSize: '1.2rem',
    fontWeight: 500,
  }));
</script>

<style lang="scss">
.app-button-container {
  transition: var(--effect);

  button {
    background-color: transparent !important;
    width: 100%;
    max-width: none;

    .loader {
      svg {
        color: var(--r2e-white);
        width: 3rem;
        height: 3rem;
      }
    }

    .button-icon {
      svg {
        margin-right: var(--margin-xs);
        color: var(--r2e-white);
        width: 1.4rem;
        height: 1.4rem;
      }
    }

  }

  :not(.native-button):hover, :not(.native-button):active {
    transform: scale(1.02);
  }

  &.native-button:active {
    transform: scale(0.98);
    opacity: 0.8;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  @media(max-width: 360px){
    transform: scale(0.8);

    &:active {
      transform: scale(0.78);
    }
  }
}
</style>

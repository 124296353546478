<template>
  <div class="auth-code-container">
    <template v-if="!loading">
      <v-otp-input
        v-model="otpCode"
        :num-inputs="6"
        :inputmode="'numeric'"
        :input-classes="'auth-code-input'"
        @on-complete="onFinish"
      />
    </template>

    <div v-else class="input-loading-animation">
      <icon name="loader" />
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, defineEmits, watch } from 'vue';
  import Icon from "@/ui/icon/Icon.vue";
  import VOtpInput from "vue3-otp-input";

  const otpCode = ref('');
  const emit = defineEmits(['codeEntered']);

  // eslint-disable-next-line no-undef
  const props = defineProps({
    code: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  });

  const onFinish = (code) => {
    emit('codeEntered', code);
  };

  watch(() => props.code, (newCode) => {
    if (newCode) {
      otpCode.value = newCode;
    }
  });

  onMounted(() => {
    otpCode.value = '';
  });
</script>



<style lang="scss">
.auth-code-container {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  gap: var(--margin-s);
  min-height: 6.1rem;

  .input-loading-animation {
    position: absolute;
    width: 100%;
    z-index: 3;
    height: 100%;
    display: flex;
    justify-content: center;
    transform: scale(1.5);

    svg {
      width: 3rem;
      height: 3rem;
      color: var(--r2e-secondary);
    }
  }
}

.auth-code-input {
  width: 6.1rem;
  height: 6.1rem;
  text-align: center;
  background-color: var(--r2e-secondary-100);
  border: 1px solid transparent;
  border-radius: 4px;
  margin: var(--margin-xs);
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  transition: var(--effect);
  font-weight: 700;


  &:active, &:focus {
    border: 1px solid var(--r2e-secondary);
    outline: var(--r2e-secondary);
  }

  @media (max-width: 450px) {
    width: 4rem;
    height: 4rem;
    font-size: 1.4rem;
  }

  @media (max-width: 300px) {
    width: 3rem;
    height: 3rem;
    font-size: 1.4rem;
  }
}

[data-theme='dark'] {
  .auth-code-input {
    background-color: var(--r2e-secondary-500);
    color: var(--r2e-white);
  }

  .auth-code-container {
    .input-loading-animation {
      svg {
        color: var(--r2e-white);
      }
    }
  }
}
</style>




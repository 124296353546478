import CartArticle from "@/resources/CartArticle.js";
import {CartLineSupplementGroup} from "@/resources/CartLineSupplementGroups.js";

export default class CartLine {
  constructor(cartLine) {
    this.uuid = null;
    this.article = null;
    this.supplement_groups = [];
    this.quantity = 1;
    this.price_unit = 0.00;
    this.price_supplements = 0.00;
    this.price_total = 0.00;
    this.comment = null;
    this.establishment_slug = null;

    if(cartLine){
      this.init(cartLine);
    }
  }

  init(data){
    this.uuid = data.uuid;
    this.article = new CartArticle(data.article);
    this.supplement_groups = data.supplement_groups.map(group => new CartLineSupplementGroup(group));
    this.quantity = data.quantity;
    this.price_unit = data.price_unit;
    this.price_supplements = data.price_supplements;
    this.price_total = data.price_total;
    this.establishment_slug = data.establishment_slug;
    this.comment = data.comment;
  }

  addComment(comment) {
    this.comment = comment;
  }

  addQuantity() {
    this.quantity++;
    this.price_total = (this.price_unit + this.price_supplements) * this.quantity;
  }

  minQuantity() {
    if(this.quantity > 1){
      this.quantity--;
      this.price_total = (this.price_unit + this.price_supplements) * this.quantity;
    }
  }
}

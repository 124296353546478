<template>
  <ion-header />
  <ion-content class="ion-padding content-radius" overflow-scroll="false">
    <div class="inner-content">
      <div class="modal-header" />
      <div class="modal-main mb-l">
        <div class="icon-wrap mb-m">
          <Icon name="version" />
        </div>
        <div>
          <p>{{ __t('app.modal.version.body') }}</p>
        </div>
      </div>
    </div>
  </ion-content>
  <ion-footer style="box-shadow: none;">
    <div class="modal-footer">
      <app-button
        class="mt-m"
        @click="submitUpdate"
      >
        {{ __t('app.modal.version.button') }}
      </app-button>
    </div>
  </ion-footer>
</template>

<script setup>
  import {defineProps} from 'vue';
  import {$showToast, __t} from '@/globals';
  import {modalController, IonHeader, IonContent, IonFooter} from "@ionic/vue";
  import Icon from "@/ui/icon/Icon.vue";
  import AppButton from "@/ui/button/AppButton.vue";
  import {preferenceService} from "@/services/app/preferenceService.js";
  const props = defineProps({
    version: {
      type: String,
      required: true
    }
  });
  const submitUpdate = async () => {
    /*localStorage.clear();
    sessionStorage.clear();

    caches.keys().then(cacheNames => {
      cacheNames.forEach(cacheName => {
        caches.delete(cacheName);
      });
    });*/

    const version = props.version;

    await preferenceService.setAppVersion(version);

    await $showToast(__t('success.version.toast'), 'success');

    await modalController.dismiss();

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }


</script>


<style lang="scss">
.ion-modal-update-version {
  --height: 36rem;

  .modal-main {
    margin: auto auto var(--margin-l);
    margin-bottom: 0;
    max-width: 38rem;

    .icon-wrap {
      display: flex;
      justify-content: center;


      svg {
        width: 4rem;
        height: 4rem;
        color: var(--r2e-primary);
      }
    }

    p {
      font-size: 1.2rem;
      font-weight: 500;
      color: var(--r2e-secondary-200);
      line-height: 1.7;
    }
  }

  .modal-footer {
    max-width: 36rem;
    margin: auto auto var(--margin-l);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

[data-theme='dark'] {
  .ion-modal-update-version {
    h3 {
      color: var(--r2e-white);

      svg {
        fill: var(--r2e-white);
      }
    }

    .modal-footer {
      button {
        background-color: var(--r2e-primary);
        color: var(--r2e-white);
      }
    }
  }
}

</style>

export function generateLocalizedRoutes(routes, locale) {
  console.log('locale:', locale);
  return routes.map((route) => ({
    ...route,
    path: route[`path_${locale}`] || route.path,
    redirect: route.redirect
      ? routes.find((r) => r.name === route.redirect)?.[`path_${locale}`]
      : route.redirect,
  }));
}

import SocialAuthService from "@/services/auth/SocialAuthService.js";
import {getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider} from "firebase/auth";
export default class SocialAuthWebService extends SocialAuthService {

  async loginWithGoogle() {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    const auth = getAuth(window.firebase_app);
    const result = await signInWithPopup(auth, provider);

    return result._tokenResponse.oauthAccessToken;
  }

  async loginWithFacebook() {
    const provider = new FacebookAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    const auth = getAuth(window.firebase_app);
    const result = await signInWithPopup(auth, provider);

    return result._tokenResponse.oauthAccessToken;

  }

  async loginWithApple() {
    throw new Error('Apple login is not supported in web');
  }
}

export default class Address {

  constructor(data) {
    this.id = null;
    this.street = null;
    this.number = null;
    this.default = null;
    this.bus = null;
    this.latitude = null;
    this.longitude = null;
    this.city = {
      name : null,
      zip : null,
      province : null,
      country : null,
      country_code : null,
    };
    this.save = false;

    if(data){
      this.init(data);
    }
  }

  init(data) {
    this.id = data.id || null;
    this.street = data.street || null;
    this.number = data.number || null;
    this.default = data.default || null;
    this.bus = data.bus || null;
    this.latitude = data.latitude || null;
    this.longitude = data.longitude || null;
    this.city = {
      name :  data.city.name,
      zip : data.city.zip,
      province : data.city.province,
      country : data.city.country,
      country_code : data.city.country_code,
    };
    this.save = data.save || false;
  }
}

export default class CartArticle {
  constructor(cartArticle) {
    this.id = null;
    this.title = null;
    this.type = null;
    this.alcohol = null;
    this.description = null;
    this.highlight = null;
    this.image_path = null;
    this.preview_path = null;
    this.price_delivery = 0.00;
    this.price_takeout = 0.00;
    this.product_nr = null;
    this.receipt_sorting = null;
    this.stock = null;
    this.stock_dependent = null;
    this.stock_unit = null;
    this.visible = null;

    if(cartArticle){
      this.init(cartArticle);
    }
  }

  init(cartArticle) {
    this.id = cartArticle.id;
    this.title = cartArticle.title;
    this.type = cartArticle.type;
    this.alcohol = cartArticle.alcohol;
    this.description = cartArticle.description;
    this.highlight = cartArticle.highlight;
    this.image_path = cartArticle.image_path;
    this.preview_path = cartArticle.preview_path;
    this.price_delivery = cartArticle.price_delivery;
    this.price_takeout = cartArticle.price_takeout;
    this.product_nr = cartArticle.product_nr;
    this.receipt_sorting = cartArticle.receipt_sorting;
    this.stock = cartArticle.stock;
    this.stock_dependent = cartArticle.stock_dependent;
    this.stock_unit = cartArticle.stock_unit;
    this.visible = cartArticle.visible;
  }
}

export default class CartSummary {
  constructor(cartSummary) {
    this.sub_total = 0.00;
    this.fee = 0.00;
    this.delivery_cost = 0.00;
    this.service_cost = 0.00;
    this.total = 0.00;
    this.discount_code = null;
    this.discount_amount = 0.00;
    this.discount_min_order_amount = 0.00;

    if(cartSummary) {
      this.init(cartSummary)
    }
  }

  init(data){
    this.sub_total = data.sub_total || 0.00;
    this.fee = data.fee || 0.00;
    this.delivery_cost = data.delivery_cost || 0.00;
    this.service_cost = data.service_cost || 0.00;
    this.total = data.total || 0.00;
    this.discount_code = data.discount_code || null;
    this.discount_amount = data.discount_amount || 0.00;
    this.discount_min_order_amount = data.discount_min_order_amount || 0.00;
  }

  calculate(items, deliveryMode) {
    this.sub_total = items.reduce((total, item) => total + (item.price_total || 0), 0);
    let total_without_service_cost = 0.00;

    if(deliveryMode === 'delivery')
    {
      total_without_service_cost = this.sub_total
        - (this.sub_total >= this.discount_min_order_amount ? this.discount_amount : 0)
        + this.fee
        + this.delivery_cost
    } else {
      total_without_service_cost = this.sub_total
        - (this.sub_total >= this.discount_min_order_amount ? this.discount_amount : 0)
        + this.fee
    }

    this.service_cost = this.calculateServiceCost(total_without_service_cost);

    this.total = total_without_service_cost + this.service_cost;
  }

  calculateServiceCost(orderTotal) {
    if (orderTotal <= 50) {
      return 0.75;
    } else if (orderTotal > 50 && orderTotal <= 100) {
      return 1.49;
    } else if (orderTotal > 100 && orderTotal <= 150) {
      return 1.99;
    } else if (orderTotal > 150 && orderTotal <= 200) {
      return 2.49;
    } else if (orderTotal > 200 && orderTotal <= 250) {
      return 2.99;
    } else {
      return 3.49;
    }
  }
}

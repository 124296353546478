import CartLineSupplement from "@/resources/CartLineSupplement.js";

export class CartLineSupplementGroup {
  constructor(supplementGroup) {
    this.id = null;
    this.value = null;
    this.description = null;
    this.type = null;
    this.min = 0;
    this.max = 0;
    this.required = false;
    this.visible = false;
    this.sorting = 0;
    this.supplements = [];

    if(supplementGroup){
      this.init(supplementGroup);
    }
  }

  init(data){
    this.id = data.id;
    this.value = data.value;
    this.description = data.description;
    this.type = data.type;
    this.min = data.min;
    this.max = data.max;
    this.required = data.required;
    this.visible = data.visible;
    this.sorting = data.sorting;
    this.supplements = data.supplements.map(supplement => new CartLineSupplement(supplement));
  }
}

import User from "@/resources/User";

export default {
  namespaced: true,
  state: {
    user: new User(),
    token: null,
    tokenExpiry: null,
    register: false,
    is_registering: false,
    registering_email: null,
    registering_password: null,
    registering_token: null,
    registering_user_id: null,
    registering_provider: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      state.register = user.register;

      if (state.user.id) {
        state.register = false;
      }

    },
    setToken(state, token) {
      state.token = token;
    },
    setTokenExpiry(state, tokenExpiry) {
      state.tokenExpiry = tokenExpiry;
    },
    toggleRegister(state) {
      state.register = !state.register;
    },
    updateUser(state, params){
      state.user.first_name = params.first_name;
      state.user.last_name = params.last_name;
      state.user.phone = params.phone;
    },
    reset(state) {
      state.user = new User();
      state.token = null;
      state.register = false
    },
    setRegistering(state, value) {
      state.is_registering = value;

      if(value === false) {
        state.register = false;
      }
    },
    setRegisteringEmail(state, email) {
      state.registering_email = email;
    },
    setRegisteringPassword(state, password) {
      state.registering_password = password;
    },
    setRegisteringToken(state, token) {
      state.registering_token = token;
    },
    setRegisteringUserId(state, user_id) {
      state.registering_user_id = user_id;
    },
    setRegisteringProvider(state, provider) {
      state.registering_provider = provider;
    }
  },
  getters: {
    user: (state) => state.user,
    token: (state) => state.token,
    tokenExpiry: (state) => state.tokenExpiry,
    register: (state) => state.register,
    is_registering: (state) => state.is_registering,
    registering_email: (state) => state.registering_email,
    registering_password: (state) => state.registering_password,
    registering_token: (state) => state.registering_token,
    registering_user_id: (state) => state.registering_user_id,
    registering_provider: (state) => state.registering_provider,
    isAuthenticated: (state) => {
      return !!state.user.id && !!state.token;
    },
  },
  actions: {
    setUser({commit}, user) {
      commit('setUser', user)
    },
    setRegistering({commit}, value) {
      commit('setRegistering', value)
    },
    setRegisteringEmail({commit}, email) {
      commit('setRegisteringEmail', email)
    },
    setRegisteringPassword({commit}, password) {
      commit('setRegisteringPassword', password)
    },
    setRegisteringToken({commit}, token) {
      commit('setRegisteringToken', token)
    },
    setRegisteringUserId({commit}, user_id) {
      commit('setRegisteringUserId', user_id)
    },
    setRegisteringProvider({commit}, provider) {
      commit('setRegisteringProvider', provider)
    },
    updateUser({commit}, params) {
      commit('updateUser', params)
    },
    setToken({commit}, token) {
      commit('setToken', token)
    },
    setTokenExpiry({commit}, token) {
      commit('setTokenExpiry', token)
    },
    toggleRegister({commit}, token) {
      commit('toggleRegister', token)
    },
    reset({commit}) {
      commit('reset');
    }
  },
};

// services/preferencesStorage.js
import {Drivers, Storage} from '@ionic/storage';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

// Storage keys as constants
const STORAGE_KEYS = {
  PREFERENCES: 'preferences_db',
};

class PreferencesStorage {
  constructor() {
    this.storage = new Storage([Drivers.IndexedDB, CordovaSQLiteDriver._driver]);
    this.storage.create();
  }

  async setPreferences(preferences) {
    try {
      await this.storage.set(STORAGE_KEYS.PREFERENCES, preferences);
    } catch (error) {
      console.error('Failed to set preferences:', error);
    }
  }

  async getPreferences() {
    try {
      return await this.storage.get(STORAGE_KEYS.PREFERENCES) || {};
    } catch (error) {
      console.error('Failed to get preferences:', error);
      return {};
    }
  }

  async clearPreferences() {
    try {
      await this.storage.remove(STORAGE_KEYS.PREFERENCES);
    } catch (error) {
      console.error('Failed to clear preferences:', error);
    }
  }

  // New method to set an individual property in preferences
  async set(key, value) {
    try {
      const preferences = await this.getPreferences();
      preferences[key] = value; // Update the specific property
      await this.setPreferences(preferences); // Save updated preferences
    } catch (error) {
      console.error(`Failed to set preference property "${key}":`, error);
    }
  }

  async get(key) {
    try {
      const preferences = await this.getPreferences();
      return preferences[key];
    } catch (error) {
      console.error(`Failed to get preference property "${key}":`, error);
    }
  }
}

// Export a single instance (Singleton pattern)
export const preferencesStorage = new PreferencesStorage();

import SocialAuthService from "@/services/auth/SocialAuthService.js";
import {FirebaseAuthentication} from "@capacitor-firebase/authentication";

export default class SocialAuthNativeService extends SocialAuthService {
  async loginWithGoogle() {
    await FirebaseAuthentication.signOut();

    const resp = await FirebaseAuthentication.signInWithGoogle({
      prompt: 'select_account',
    });

    return resp.credential.accessToken;
  }

  async loginWithFacebook() {
    await FirebaseAuthentication.signOut();
    const resp = await FirebaseAuthentication.signInWithFacebook(
      {prompt: 'select_account'}
    );

    return resp.credential.accessToken;
  }

  async loginWithApple() {
    try {
      await FirebaseAuthentication.signOut();

      const result = await FirebaseAuthentication.signInWithApple({
        skipNativeAuth: false,
        scopes: ['name', 'email'],
        locale: 'nl_BE'
      });

      if (!result.credential) {
        throw new Error('No credentials received from Apple Sign-In');
      }

      return {
        token: result.credential.idToken,
        userResponse: result.user
      }
    } catch (error) {
      console.error('Apple Sign-In Error:', JSON.stringify(error, null, 2));
      throw error;
    }
  }
}

<template>
  <app-modal-mini
    class="ion-modal-delivery"
    :button-disabled="arrivalTimingPreference !== 'now' && disabled"
    @submitted="submit"
  >
    <template #header>
      <h3>
        <Icon name="clock" />
        {{ deliveryMethod === 'delivery' ? __t('checkout.delivery-time.title') : __t('checkout.takeout-time.title') }}
      </h3>
    </template>

    <template #main>
      <div class="delivery-time-modal">
        <div class="toggle-group">
          <span :class="{active: arrivalTimingPreference === 'now'}">{{ __t('checkout.delivery-time.asap') }}</span>
          <Toggle
            :active="arrivalTimingPreference === 'now'"
            @click="togglePreference"
          />
        </div>

        <template v-if="arrivalTimingPreference !== 'now'">
          <div class="mt-m or">
            {{ __t('app.or') }}
          </div>

          <div class="date-picker mt-m">
            <label>{{ __t('app.date') }}</label>
            <DatePicker
              class="mb-m"
              :value="inputs.date.value"
              :time="false"
              :configuration="configuration"
              @setter="val => inputs.date.value = val"
            />
          </div>

          <Time
            id="time"
            :value="inputs.time.value"
            :label="__t('app.time')"
            :placeholder="__t('app.time')"
            :error="inputs.time.error ? inputs.time.error_message : null"
            @set="val => inputs.time.value = val"
            @remove-error="inputs.time.error = false"
          />
        </template>

        <div v-if="establishment && !establishment.is_open" class="closed mt-s">
          (<template v-if="establishment.open_time.day === 'today'">
            {{ __t('app.establishment.today') }} {{ establishment.open_time.time }}
          </template>
          <template v-else-if="establishment.open_time.day === 'tomorrow'">
            {{ __t('app.establishment.tomorrow') }} {{ establishment.open_time.time }}
          </template>
          <template v-else>
            {{ __t('app.establishment.other_day') }} {{ establishment.open_time.day }} - {{ establishment.open_time.time }}
          </template>)
        </div>
      </div>
    </template>
  </app-modal-mini>
</template>

<script setup>
  import {ref, computed, defineProps,  onMounted} from 'vue';
  import { Icon, Toggle, DatePicker, Time } from "@/ui";
  import AppModalMini from "@/modals/AppModalMini.vue";
  import {$showToast, __t} from "@/globals";
  import { getEstablishmentConfiguration } from "@/modules/establishment/services/EstablishmentService";
  import moment from "moment";
  import {modalController} from "@ionic/vue";
  import {cartService} from "@/services/app/cartService.js";
  import {preferenceService} from "@/services/app/preferenceService.js";
  import {userService} from "@/services/app/userService.js";

  defineProps({
    takAway: {type: Boolean, default: false}
  });

  const loading = ref(true);

  const inputs = ref({
    date: {
      value: null,
    },
    time: {
      value: null,
      error: false,
      error_message: __t('error.input.time')
    }
  });

  const configuration = ref(null);
  const deliveryAddress = ref(null);
  const deliveryMethod = ref('delivery');
  const establishment = ref(null);
  const arrivalTimingPreference = ref(null);
  const preferredTimeOfArrival = ref(null);

  const disabled = computed(() => inputs.value.date.value === null || inputs.value.time.value === null);

  const initData = async () => {
    deliveryAddress.value = await userService.getUserAddress();
    deliveryMethod.value = await cartService.getDeliveryMode();
    establishment.value = await cartService.getEstablishment();
    arrivalTimingPreference.value = await preferenceService.getArrivalTimingPreference();
    preferredTimeOfArrival.value = await preferenceService.getPreferredTimeOfArrival();

    configuration.value = getEstablishmentConfiguration(establishment.value);

    inputs.value.time.value = preferredTimeOfArrival.value
      ? moment(preferredTimeOfArrival.value, 'DD/MM/YYYY HH:mm').format('HH:mm')
      : configuration.value.startTime.hours + ":" + configuration.value.startTime.minutes;

    inputs.value.date.value = preferredTimeOfArrival.value
      ? moment(preferredTimeOfArrival.value, 'DD/MM/YYYY').toDate()
      : moment().toDate();

  }

  onMounted(async () => {
    await initData();

    setTimeout(async () => {
      await setBreakpoint();
    }, 400);
  });

  async function togglePreference() {
    if (arrivalTimingPreference.value === 'now') {
      arrivalTimingPreference.value = 'other';
    } else {
      arrivalTimingPreference.value = 'now';
    }

    await setBreakpoint()
  }

  async function setBreakpoint() {
    const modal = await modalController.getTop();

    if (arrivalTimingPreference.value === 'now') {
      await modal.setCurrentBreakpoint(0.5);
    }else {
      await modal.setCurrentBreakpoint(1);
    }
  }

  async function submit() {
    if (arrivalTimingPreference.value === 'now') {
      await preferenceService.setArrivalTimingPreference('now');
      await preferenceService.setPreferredTimeOfArrival(
        moment(inputs.value.date.value).format('DD/MM/YYYY'),
        null
      )
      await modalController.dismiss();
    } else {
      await preferenceService.setArrivalTimingPreference('other');

      if (inputs.value.date.value && inputs.value.time.value) {
        await preferenceService.setPreferredTimeOfArrival(
          moment(inputs.value.date.value).format('DD/MM/YYYY'),
          inputs.value.time.value
        )
        await modalController.dismiss();
      } else {
        $showToast(__t('error.input.delivery-time'), 'error');
      }
    }
  }
</script>

<style lang="scss">
.ion-modal-delivery {
--height: 60rem;
}

.delivery-time-modal {
  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  .date-picker {
    label {
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 2.6;
      color: var(--r2e-secondary);
      padding-bottom: 5px;
    }
  }

  .or {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    color: var(--r2e-secondary-200);
  }

  .toggle-group {
    margin: var(--margin-l) 0;
    margin-bottom: var(--margin-m);
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    span {
      font-size: 1.2rem;
      font-weight: 600;
      margin-right: var(--margin-s);

      &.active {
        color: var(--r2e-primary);
      }
    }
  }

  .closed {
    font-size: 1rem;
    text-align: center;
    font-weight: 600;
    color: var(--r2e-secondary-200);
  }
}

</style>

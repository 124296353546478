import { Drivers, Storage } from '@ionic/storage';
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

// Storage keys as constants
const STORAGE_KEYS = {
  APP_CONFIGURATION: 'app_configuration_store',
};

export class AppConfigurationStore {
  constructor() {
    this.store = new Storage({
      driverOrder: [Drivers.IndexedDB, CordovaSQLiteDriver._driver],
    });

    this.store.create();
  }

  async set(key, value) {
    try {
      const settings = await this.getAppConfiguration();
      if (settings[key] === value) return;
      settings[key] = value;
      await this.setAppConfiguration(settings);
    } catch (error) {
      console.error(`Failed to set app configuration property "${key}":`, error);
    }
  }

  async get(key) {
    try {
      const settings = await this.getAppConfiguration();
      return settings[key];
    } catch (error) {
      console.error(`Failed to get app configuration property "${key}":`, error);
      return undefined;
    }
  }

  async setAppConfiguration(configuration) {
    try {
      await this.store.set(STORAGE_KEYS.APP_CONFIGURATION, configuration);
    } catch (error) {
      console.error('Failed to set app configuration:', error);
    }
  }

  async getAppConfiguration() {
    try {
      return (await this.store.get(STORAGE_KEYS.APP_CONFIGURATION)) || {};
    } catch (error) {
      console.error('Failed to get app configuration:', error);
      return {};
    }
  }

  async clearAppConfiguration() {
    try {
      await this.store.remove(STORAGE_KEYS.APP_CONFIGURATION);
    } catch (error) {
      console.error('Failed to clear app configuration:', error);
    }
  }
}

import {AppConfigurationRepository} from "../repositories/AppConfigurationRepository.js";
import {AppConfigurationStore} from "../stores/AppConfigurationStore.js";

class AppConfigurationService {
  constructor() {
    this.appConfigurationRepository = new AppConfigurationRepository();
    this.appConfigurationStore = new AppConfigurationStore();
    this.appStateListeners = [];
  }

  async loadConfiguration() {
    try {
      const config = await this.appConfigurationRepository.fetchConfiguration();

      await this.appConfigurationStore.setAppConfiguration(config);
    } catch (error) {
      console.error('Failed to load app configuration:', error);
      throw error;
    }
  }

  async getAppState() {
    try {
      return await this.appConfigurationStore.get('state');
    }
    catch (error) {
      console.error('Failed to get app state:', error);
      throw error;
    }
  }

  async getGlobalDiscountCode() {
    try {
      return await this.appConfigurationStore.get('globalDiscountCode');
    }
    catch (error) {
      console.error('Failed to get global discount code:', error);
      throw error;
    }
  }

  async initializeEvent() {
    await this.loadConfiguration();

    // Set up interval for periodic updates
    this.configInterval = setInterval(() => {
      this.loadConfiguration();
    }, 30000);
  }

  stopEvent() {
    if (this.configInterval) {
      clearInterval(this.configInterval);
      this.configInterval = null;
    }
  }
}

export const appConfigurationService = new AppConfigurationService();

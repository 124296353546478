export async function loadTranslations() {
  const app = await import('@/translations/app.json');
  const account = await import('@/translations/account.json');
  const checkout = await import('@/translations/checkout.json');
  const auth = await import('@/translations/auth.json');
  const contact = await import('@/translations/contact.json');
  const errors = await import('@/translations/errors.json');
  const nav = await import('@/translations/nav.json');
  const notifications = await import('@/translations/notifications.json');
  const order = await import('@/translations/order.json');
  const routes = await import('@/translations/routes.json');
  const onboarding = await import('@/translations/onboarding.json');
  const modal = await import('@/translations/modal.json');

 const allTranslations = app.default.concat(
   account.default,
   checkout.default,
   auth.default,
   checkout.default,
    contact.default,
    errors.default,
    nav.default,
    notifications.default,
    order.default,
    routes.default,
    onboarding.default,
    modal.default
 );

 window.translations = allTranslations;

 return allTranslations;
}

<template>
  <div class="article-modal-header" :class="{'with-image' : native && article.image_path}">
    <button class="reset exit-button" @click="emit('close')">
      <Icon name="exit" />
    </button>
    <div v-if="article.image_path" class="image" :style="backgroundImageStyle" />
    <div class="content">
      <h2 class="article-title">
        {{ article.title }}
        <span v-if="article.alcohol" class="alcohol-title ml-s" @click.stop.prevent>
          <Icon name="alcohol" />
          <Tooltip>{{ __t('app.alcohol') }}</Tooltip>
        </span>
      </h2>
      <div class="article-description" v-html="article.description" />
      <div v-if="article.allergies.length > 0" class="allergies" @click.stop.prevent>
        <template v-for="(allergy, i) in article.allergies" :key="`allergy_${i}`">
          <div class="allergy">
            <Icon :name="`allergy.${allergy.key}`" />
            <Tooltip>{{ allergy.value }}</Tooltip>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { defineProps, defineEmits } from 'vue';
  import { Icon } from "@/ui";
  import { __t } from "@/globals.js";
  import Tooltip from "@/modules/global/parts/Tooltip.vue";
  import PlatformService from "@/services/PlatformService.js";

  const props = defineProps({
    article: {
      type: Object,
      required: true,
    },
  });

  const native = PlatformService.isNative();
  const emit = defineEmits(['close']);

  const imageLoaded = ref(false);

  const backgroundImageStyle = computed(() => ({
    backgroundImage: `url(${props.article.image_path})`,
    opacity: imageLoaded.value ? 1 : 0,
  }));

  const loadBackgroundImage = () => {
    if (props.article.image_path) {
      const img = new Image();
      img.src = props.article.image_path;

      img.onload = () => {
        imageLoaded.value = true;
      };

      img.onerror = () => {
        imageLoaded.value = true;
      };
    } else {
      imageLoaded.value = true;
    }
  };

  loadBackgroundImage();
</script>

<style lang="scss">
.article-modal-header {
  position: relative;
  z-index: 99;
  width: 100%;
  overflow: hidden;
  margin-bottom: 0;
  padding-top: var(--ion-safe-area-top);

  &.with-image {
    padding-top: 0;

    .exit-button {
      top: calc((-1 * (var(--ion-safe-area-top))) + var(--margin-m));
      right: var(--margin-m);
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--r2e-secondary);

      border-radius: 7px;
      i {
        svg {
          width: 1rem;
          height: 1rem;
          color: var(--r2e-secondary-100);
        }
      }
    }
  }


  .exit-button {
    margin-top: var(--ion-safe-area-top);
    position: absolute;
    right: 1rem;
    top: var(--margin-m);
    width: 1.6rem;
    z-index: 9999;
    height: 1.6rem;
  }

  .image {
    width: 100%;
    height: 25rem;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    background-color: var(--r2e-secondary-100); // Default background color
  }

  .content {
    position: relative;
    padding: var(--margin-m);
    padding-top: var(--padding-m);
    margin-bottom: 0;

    @media (min-width: 720px) {
      overflow-y: scroll;
      padding-right: var(--margin-m);
      padding-bottom: var(--padding-l);
      box-sizing: content-box;
    }

    .article-title {
      font-size: 1.6rem;
      font-weight: 700;
    }

    .alcohol-title {
      position: relative;
      width: 2.4rem;
      height: 2.4rem;

      &:hover {
        .tooltip {
          display: flex;
          top: 2.4rem;
        }
      }

      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    .article-description {
      margin-top: var(--margin-s);
      min-height: 3.3rem;
      font-size: 1.1rem;
      color: var(--r2e-secondary);
      font-weight: 500;
    }

    .allergies {
      display: flex;
      margin-bottom: var(--margin-l);

      .allergy {
        width: 2.4rem;
        height: 1.4rem;
        margin-right: var(--margin-s);
        position: relative;

        &:hover {
          .tooltip {
            display: flex;
          }
        }

        svg {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
  }
}


[data-theme='dark'] {
  .article-modal-header {
    .exit-button {
      svg {
        fill: var(--r2e-secondary-100);
      }
    }

    .content {
      h2 {
        color: var(--r2e-white);

        svg {
          fill: var(--r2e-white);
        }
      }

      .article-description {
        color: var(--r2e-secondary-200);
      }

      .allergies {
        .allergy {
          svg {
            fill: var(--r2e-white);
          }
        }
      }
    }
  }
}
</style>

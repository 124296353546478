import axios from "axios";

export const baseUrl = `${process.env.API_URL}/v1`;

const apiClient = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export default apiClient;

<template>
  <svg
    v-if="!authenticated || (user && user.profile_image_path === null)"
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 20C0 10.5719 0 5.85786 2.92893 2.92893C5.85786 0 10.5719 0 20 0H36C45.4281 0 50.1421 0 53.0711 2.92893C56 5.85786 56 10.5719 56 20V36C56 45.4281 56 50.1421 53.0711 53.0711C50.1421 56 45.4281 56 36 56H20C10.5719 56 5.85786 56 2.92893 53.0711C0 50.1421 0 45.4281 0 36V20Z"
      fill="#2D3142"
    />
    <path
      d="M32.7027 30.3156H23.255C21.7195 30.3175 20.2474 30.9283 19.1616 32.0141C18.0759 33.0998 17.4651 34.5719 17.4632 36.1074V41.9997H38.4945V36.1074C38.4926 34.5719 37.8818 33.0998 36.796 32.0141C35.7103 30.9283 34.2382 30.3175 32.7027 30.3156Z"
      fill="white"
    />
    <path
      d="M27.9788 27.9788C31.8506 27.9788 34.9893 24.8402 34.9893 20.9684C34.9893 17.0967 31.8506 13.958 27.9788 13.958C24.1071 13.958 20.9684 17.0967 20.9684 20.9684C20.9684 24.8402 24.1071 27.9788 27.9788 27.9788Z"
      fill="white"
    />
  </svg>
  <img
    v-else
    class="profile-image"
    :src="user?.profile_image_path"
    alt="Profile image"
  >
</template>

<script setup>
  import {onBeforeMount, ref} from "vue";
  import {userService} from "@/services/app/userService.js";

  const authenticated = ref(null);
  const user = ref(null);

  onBeforeMount(async () => {
    authenticated.value = await userService.isAuthenticated();
    user.value = await userService.getUser();
  });
</script>

<style lang="scss">
.profile-image {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  object-fit: cover;
}
</style>
